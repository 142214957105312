<template>
  <section class="invoice-preview-wrapper">
    <b-row v-if="invoiceData" class="invoice-preview">
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <b-col cols="12" xl="6" class="p-0" v-if="invoiceData.transaction">
                <div>
                  <h6 class="mb-2">
                    Detalles de Transacción:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Metodo de Pago:
                        </td>
                        <td>
                          <span class="font-weight-bold" v-for="paymentType in invoiceData.transaction.payments">
                            <span v-if="paymentType.refId">
                              {{ paymentType.paymentMethod }} Referencia: {{ paymentType.refId }}
                            </span>
                            <span v-else>
                              {{ paymentType.paymentMethod }}
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Estado:
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ invoiceData.transaction.status }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Fecha:
                        </td>
                        <td>{{ invoiceData.transaction.created_at | formatDateTimeHumane }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Tipo
                        </td>
                        <td>{{ invoiceData.transaction.type }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Saldo Anterior
                        </td>
                        <td>
                          <span v-if="invoiceData.transaction.previous_balance">
                            {{ invoiceData.transaction.previous_balance | currency({ symbol: '$', precision: 2 }) }}
                          </span>
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          USD
                        </td>
                        <td>
                          {{ invoiceData.total | currency({ symbol: '$', precision: 2 }) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          VEF
                        </td>
                        <td>
                          {{ rate * invoiceData.total | currency({ symbol: 'VEF', precision: 2 }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col cols="12" xl="6" class="p-0">
              <div class="mb-1 mt-0 w-10 d-flex justify-content-end p-0">
                  <b-button variant="primary" @click="redirectAndPrint">
                    <feather-icon icon="PrinterIcon" size="15" />
                  </b-button>
                </div>
                <div class="mt-md-0">
                  <h4 class="text-center">
                      <b-alert
                      show
                      fade
                      class="mb-0"
                      :variant="statusGetClass(invoiceData.transaction.status)">
                      <div class="alert-body">
                        Factura #{{ invoiceData.id }}
                      </div>
                      </b-alert>
                    <vue-qr :text="JSON.stringify({transactionId: invoiceData.transaction.id, invoiceId:invoiceData.id})" :size="130" :margin="0" :logoCornerRadius="10" :bindElement="true"></vue-qr>
                  </h4>
                </div>
              </b-col>

            </b-row>
          </b-card-body>

          <b-table-lite
            responsive
            :items="invoiceData.products"
            :fields="tableColumns">
            
            <template #cell(product_price)="data">
              <b-card-text class="font-weight-bold mb-25 text-nowrap">
                {{ data.item.product_price | currency({ symbol: '$', precision: 2 })}}
              </b-card-text>
            </template>

            <template #cell(priceWithDiscount)="data">
              <b-card-text class="font-weight-bold mb-25 text-nowrap" v-if="data.item.discount_type == 'percent'">
                {{ data.item.price - (data.item.price * data.item.discount / 100)  | currency({ symbol: '$', precision: 2 }) }}
              </b-card-text>
              <b-card-text class="font-weight-bold mb-25 text-nowrap" v-else>
                No Aplica
              </b-card-text>
            </template>

            <template #cell(discount_type)="data">
              <b-card-text class="font-weight-bold mb-25 text-nowrap" v-if="data.item.discount_type == 'percent'">
                {{ data.item.discount | currency({ symbol: '', precision: 1 })}}{{ data.item.discount_type == 'percent' ? '%' : '$' }}
              </b-card-text>
              <b-card-text class="font-weight-bold mb-25 text-nowrap" v-else>
                No Aplica
              </b-card-text>
            </template>

            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25 text-nowrap">
                {{ showPriceTotal(data.item) | currency({ symbol: '$', precision: 2 }) }}
              </b-card-text>
            </template>

          </b-table-lite>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueQr from 'vue-qr'
import { formatDateTimeHumane } from '@core/utils/filter.js'
export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VueQr,
  },
  filters: {
    formatDateTimeHumane,
  },
  data() {
    return {
      invoiceData: {
        transaction: {
          id:{},
          payment_type: {}
        }
      },
      tableColumns: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: true,
        },
        {
          key: 'product_price',
          label: 'Precio de Producto',
          sortable: true,
        },
        {
          key: 'priceWithDiscount',
          label: 'Precio con descuento',
          sortable: true,
        },
        
        {
          key: 'discount_type',
          label: 'Descuento',
          sortable: true,
        },
        {
          key: 'qty',
          label: 'Cantidad',
          sortable: true,
        },
        {
          key: 'total',
          label: 'Total',
          sortable: true,
        }
      ],
    }
  },
  computed:{
    qrUrl(){
      return process.env.VUE_APP_API_URL + 'api/invoice/verify/' + router.currentRoute.params.id
    },
    rate() {
      return this.$store.getters['bank/getRate']
    },
  },
  methods: {
    async fetchInvoiceData() {
      this.$store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then(response => {
        this.invoiceData = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.invoiceData = undefined
        }
      })
    },
    showPriceTotal({ discount_type, price, discount, qty, total }){
      if(discount_type == 'percent'){ 
        return (price - (price * (discount / 100))) * qty 
      }else{
        return total
      } 
    },
    statusGetClass(status){
      switch (status) {
        case 'Pendiente':
          return 'warning'
        case 'Aprobado':
          return 'success'
        case 'Rechazado':
          return 'danger'
        default:
          return 'secondary'
      }
    },
     redirectAndPrint(){
      console.log(this.invoiceData.id)
       this.$router.push({
        name: "apps-invoice-ticket",
        params: { id: this.invoiceData.id },  
        query: { index: true }
      });
    }
  },
  mounted() {
    this.fetchInvoiceData()
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
